<nav [ngClass]="{ 'new-search-entry': isNewSearchentry }">
  <div *ngIf="!isNewSearchentry">
    <app-breadcrumb> </app-breadcrumb>
  </div>
  <ng-container *ngIf="isNewSearchentry">
    <div>
      <app-breadcrumb *ngIf="showBreadcrumb$ | async; else title">
      </app-breadcrumb>
    </div>
    <ng-template #title>
      <h2 class="title">Brand Landscape Analyzer</h2>
    </ng-template>
  </ng-container>
  <div>
    <app-admin-link *ngIf="showAdminConsole$ | async"></app-admin-link>

    <button
      mat-button
      [matMenuTriggerFor]="menu"
      class="cdx-but-menu menu"
      role="button"
    >
      <mat-icon fontSet="material-symbols-outlined">menu</mat-icon>
      Menu
    </button>

    <mat-menu #menu="matMenu">
      <div mat-menu-item>
        <app-history-link
          *ngIf="showHistoryLink$ | async"
          class="menu-item"
        ></app-history-link>
      </div>

      <ng-container *ngIf="showHistoryLink$ | async" class="menu-item">
        <div mat-menu-item>
          <app-account-link class="menu-item"></app-account-link>
        </div>
      </ng-container>
    </mat-menu>

    <app-notification-dropdown
      *ngIf="showHistoryLink$ | async"
    ></app-notification-dropdown>
  </div>
</nav>
