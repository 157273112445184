import { Injectable } from '@angular/core';
import { OtiService } from '@sp/oti-snippet';
import { filter, first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { UserService } from './user.service';

/* istanbul ignore next */
@Injectable()
export class AnalyticsService {
  constructor(private userService: UserService) {}

  async init(): Promise<void> {
    if (environment.analytics.enabled) {
      const user = await this.userService
        .getCurrentUserObservable()
        .pipe(
          filter((u) => !!u.id),
          first(),
        )
        .toPromise();

      const config = {
        app_id: 'brand-landscape-analyzer',
        ot_key: environment.analytics.oneTrust.apiKey,
        prod_domain: environment.analytics.oneTrust.productionUrl,
        pendo_key: environment.analytics.pendo.apiKey,
        zendesk_key: environment.analytics.zendesk.apiKey,
        zendesk_appName: 'brand-landscape-analyzer',
        zendesk_environment: environment.analytics.zendesk.environment,
        pendo_url:
          'https://content.usage.apps.compumark.com/agent/static/' +
          environment.analytics.pendo.apiKey +
          '/pendo.js',
        pendo_visitor_url:
          environment.backendBaseUrl + '/pendo/visitor/agent/value?create=true',
        pendo_dnp_url:
          environment.backendBaseUrl +
          `/pendo/visitor/pendo/value/${user!.id}/donotprocess`,
        pendo_metadata_url:
          environment.backendBaseUrl +
          `/pendo/visitor/custom/value/${user!.id}/APPID_targetingcookies`,
        pendo_details: {
          visitor: {
            id: user!.id,
            name: user!.name,
            email: user!.email,
          },
          account: {
            id: 'bla-trial',
          },
        },
        pendo_visitor_payload: [
          {
            visitorId: user!.id,
            values: { name: user!.name },
          },
        ],
      };
      const otiService = new OtiService();
      otiService.setDebug(true);
      otiService.initialize(config);
    }
  }
}
