export const environment = {
  production: false,
  backendBaseUrl:
    'https://brand-landscape-analyzer-dvlp.dvlp.int.compumark.com/brand-landscape-api',
  termsAndCondition: {
    url: 'http://termsandconditions.dvlp.int.compumark.com/',
    enabled: true,
  },
  license: {
    agGrid:
      'CompanyName=Insight Enterprises, Inc._on_behalf_of_Clarivate Analytics (US) LLC,LicensedGroup=SelectionTool,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=5,LicensedProductionInstancesCount=1,AssetReference=AG-037035,SupportServicesEnd=28_April_2024_[v2]_MTcxNDI1ODgwMDAwMA==01026c72a8c6b3e4b83471f5ab92a796',
  },
  features: {
    newSearchEntry: false,
    fullText: true,
    allThreats: true,
    allThreatsPolling: true,
    threatOlderReset: false,
    pricing: true,
    export: true,
    'deep-dive': {
      litigation: {
        tab: true,
        'direct-case-history': true,
        'reputation-fame': true,
        'litigiousness-of-owner': true,
      },
      owner: {
        tab: true,
        globality: true,
        'portfolio-size': true,
      },
      products: {
        tab: true,
      },
      'in-use': {
        tab: true,
      },
      'prediction-score': {
        tab: true,
      },
      'geographic-presence': {
        tab: true,
      },
    },
    threatOrderReset: true,
  },
  analytics: {
    enabled: true,
    pendo: {
      apiKey: '8429983b-25a8-439b-5163-3e43c200ec44',
    },
    zendesk: {
      apiKey: 'be20f5ea-79cd-4c7a-89ab-156a63d0c485',
      environment: 'snapshot',
    },
    oneTrust: {
      apiKey: '0253b910-4306-422b-ac3a-f9fb94674594',
      productionUrl: 'brand-landscape-analyzer.apps.compumark.com',
    },
  },
  maxExportTopThreatsLimit: 500,
  skipExportSubSectionsForStandartFeatureLevel: ['includeGraphics'],
};
