import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component } from '@angular/core';
import {
  ActivatedRoute,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import {
  GlasspaneService,
  InUseNotificationService,
  InUsePollingService,
} from '@compumark/brand-context-components';
import { Observable, of } from 'rxjs';
import { delay, filter, map, tap } from 'rxjs/operators';

import { AnalyticsService } from './security/services/analytics.service';
import { FullscreenService } from './util/services/fullscreen.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  loading$: Observable<boolean> = of(false);
  fullscreen = this.fullscreenService.fullscreenStatus;

  constructor(
    router: Router,
    private route: ActivatedRoute,
    glasspaneService: GlasspaneService,
    inUsePollingService: InUsePollingService,
    private analyticsService: AnalyticsService,
    private inUseNotificationService: InUseNotificationService,
    private fullscreenService: FullscreenService,
  ) {
    if (!this.isAccessDenied()) {
      this.analyticsService.init();
      inUsePollingService.init().subscribe();

      inUsePollingService.notifyWhenTerminated$.subscribe((s) =>
        this.inUseNotificationService.displaySnackBarWhenCompleted(s, [
          '/summary/isolate/results-delivery',
          s.context.resultsDeliveryId,
          'top-threats',
          'threat',
          s.parentId,
          'deep-dive',
          'in-use',
        ]),
      );

      this.loading$ = router.events.pipe(
        delay(0),
        filter((event) => this.isEventTypeRelevantToLoading(event)),
        map((event) => this.shouldEventTypeTriggerLoading(event)),
        tap(() =>
          coerceBooleanProperty(
            router.getCurrentNavigation()?.extras.state?.glasspane,
          )
            ? glasspaneService.showGlasspane()
            : glasspaneService.hideGlasspane(),
        ),
      );
    }
  }

  private isAccessDenied(): boolean {
    return window.location.href.indexOf('access-denied') !== -1;
  }

  private isEventTypeRelevantToLoading(event: Event): boolean {
    return (
      event instanceof NavigationStart ||
      event instanceof NavigationEnd ||
      event instanceof NavigationCancel ||
      event instanceof NavigationError
    );
  }

  private shouldEventTypeTriggerLoading(event: Event): boolean {
    return event instanceof NavigationStart;
  }

  get resultsDeliveryId(): string {
    return this.route.snapshot.params.resultsDeliveryId;
  }
}
