import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FullscreenService {
  private isFullscreen = new BehaviorSubject<boolean>(false);

  get fullscreenStatus(): BehaviorSubject<boolean> {
    return this.isFullscreen;
  }

  set fullscreen(value: boolean) {
    this.isFullscreen.next(value);
  }

  toggleFullscreen(): void {
    this.isFullscreen.next(!this.isFullscreen.getValue());
  }
}
