import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ContentType } from '@compumark/bla-backend-client';
import { GlasspaneService } from '@compumark/brand-context-components';
import { UserPreferencesService } from '@compumark/user-preferences-backend-specifications';
import { UserService } from 'src/app/security/services/user.service';
import { SnackbarService } from 'src/app/util/components/snackbar/snackbar.service';
import { CommonPreferencesService } from 'src/app/util/services/common-preference.service';

export interface TableSetting {
  name: string;
  value: string;
}

export const allTableSettings: any = [
  {
    title: 'Row density',
    subsection: [
      {
        value: 'Default',
        image: '../../assets/img/Row density - default.svg',
        selected: true,
      },
      {
        value: 'Comfortable',
        image: '../../assets/img/Row density - comfortable.svg',
        selected: false,
      },
      {
        value: 'Wide',
        image: '../../assets/img/Highlighting - off.svg',
        selected: false,
      },
    ],
  },

  {
    title: 'Reading pane',
    subsection: [
      {
        value: 'No split',
        image: '../../assets/img/Reading pane - no split.svg',
        selected: true,
      },
      {
        value: 'Split view',
        image: '../../assets/img/Reading pane - Split View.svg',
        selected: false,
      },
    ],
  },
  {
    title: 'Goods and Services highlighting',
    subsection: [
      {
        value: 'Highlighting off',
        image: '../../assets/img/Highlighting - off.svg',
        selected: true,
      },
      {
        value: 'Risk ranking highlighting',
        image: '../../assets/img/Highlighting - risk highlighting on.svg',
        selected: false,
      },
    ],
  },
];

@Component({
  selector: 'app-table-settings',
  templateUrl: './table-settings.component.html',
  styleUrls: ['./table-settings.component.scss'],
})
export class TableSettingsComponent implements OnInit {
  isSideNavOpen = false;
  modified = false;
  sections: any = allTableSettings;
  @Input() contentType?: ContentType;
  settingsTooltip = 'Table settings';
  userPreference: any = undefined;
  favoriteViews = JSON.parse(JSON.stringify(this.sections));
  @Output() tableSetting = new EventEmitter<TableSetting[]>();
  @Output() triggerRefreshTable = new EventEmitter<void>();

  ngOnInit(): void {
    if (this.contentType !== 'TRADEMARK') {
      this.favoriteViews.pop();
    }
  }

  constructor(
    private userService: UserService,
    private userPreferenceService: UserPreferencesService,
    private snackBarService: SnackbarService,
    private commonPreferencesService: CommonPreferencesService,
    private glasspaneService: GlasspaneService,
    private eRef: ElementRef,
  ) {
    userService.refreshCurrentUser();
    this.userService.getCurrentUserObservable().subscribe((u) => {
      this.userPreference = u.preference!;

      if (!!this.userPreference?.tableSettings) {
        this.favoriteViews.forEach((section: any) => {
          section.subsection.forEach((subsection: any) => {
            const selectedValue = this.userPreference.tableSettings.find(
              (s: any) => s.name === section.title,
            );
            subsection.selected = selectedValue.value === subsection.value;
          });
        });
      }
    });
  }

  updateView(section: any, value: string): void {
    section.subsection.forEach((subsection: any) => {
      subsection.selected = value === subsection.value;
    });
    this.notifySettings();
    this.saveSettings();
  }

  toggleSideNav(): void {
    this.isSideNavOpen = !this.isSideNavOpen;
    if (this.modified) {
      this.saveSettings();
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event): void {
    if (this.isSideNavOpen && !this.eRef.nativeElement.contains(event.target)) {
      this.toggleSideNav();
    }
  }

  showLoadingOverlay(): void {
    this.glasspaneService.showGlasspane();
  }

  refreshTable(): void {
    this.triggerRefreshTable.emit();
  }

  notifySettings(): void {
    this.modified = true;
    this.tableSetting.emit(this.getSelectedSettings(this.favoriteViews));
  }

  getSelectedSettings(settings: any): any {
    if (!!settings) {
      return settings.map((view: any) => ({
        name: view.title,
        value: this.getSelectedValue(view).value,
      }));
    }
    return undefined;
  }

  saveSettings(): void {
    if (!!this.userPreference) {
      if (!this.userPreference.tableSettings) {
        this.userPreference.tableSettings = [];
      }
      this.userPreference.tableSettings = !!this.getSelectedSettings(
        this.favoriteViews,
      )
        ? this.getSelectedSettings(this.favoriteViews)
        : this.getSelectedSettings(this.sections);

      this.userPreferenceService
        .saveUserPreferences(this.userPreference)
        .subscribe(
          (pref) => {
            this.userPreference = pref.preference;
          },
          () => {
            this.snackBarService.openSnackBar('Unable to save Table settings.');
          },
          () => {
            this.snackBarService.openSnackBar('Table settings saved.');
            this.commonPreferencesService.setUserPreference(true);
            this.modified = false;
          },
        );
    }
  }

  getSelectedValue(section: any): any {
    return section.subsection.find((s: any) => s.selected);
  }
}
